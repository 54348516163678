// import * as serviceWorker from './serviceWorker'

import { Helmet } from 'react-helmet'
import React from 'react'

const Root = ({ children, initialState = {} }) => (
    <React.Fragment>
        {localStorage.getItem('customCSS') !== "NULL" && (
            <Helmet>
                <style type="text/css">{localStorage.getItem('customCSS')}</style>
            </Helmet>
        )}
        <div id="wrapper">{children}</div>
    </React.Fragment>
)

// serviceWorker.register();

export default Root;
