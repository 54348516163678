import { BrowserRouter, Route, Switch } from 'react-router-dom'

// import App from './components/App';
import Loadable from 'react-loadable'
import React, { Component, Suspense } from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import './index.css'

const Home = Loadable({
  loader: () => import('./components/Home'),
  loading: () => 'Loading...'
})

const Watch = Loadable({
  loader: () => import('./components/Watch'),
  loading: () => 'Loading...'
})

const Browse = Loadable({
  loader: () => import('./components/Browse'),
  loading: () => 'Loading...'
})

const NotFound = Loadable({
  loader: () => import('./components/NotFound'),
  loading: () => 'Loading...'
})

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
}

class AppRender extends Component {
  render() {

    if (window.location.href.indexOf(".html") >= 0) {
      var url = window.location.href
      var id = url.split("-").pop().replace('.html', '')
      var title = url.split("/").pop().replace("-stream-" + id + ".html", '')
      var redirect = "/watch/" + title + "/" + id
      window.location = redirect
      return false;
    }

    return <Root>
      <BrowserRouter>
        <React.Fragment>
          <Route component={ScrollToTop} />
          <Switch>
            <Route path={'/'} exact component={Home} />
            <Route path={'/watch/:title/:id'} exact component={Watch} />
            <Route path={'/browse/'} exact component={Browse} />

            <Route component={NotFound} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    </Root>
  }
}

// ReactDOM.render(
//   <AppRender />,
//   document.getElementById('root')
// )



ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div style={{ fontSize: "30px", textAlign: "center" }}>Loading...</div>}>
      <AppRender />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);